import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import "../styles/index.scss"
import Img from "gatsby-image"

function PostsLayout() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark (
        filter: {frontmatter: {published: {eq: true}}}
        sort: {fields: [frontmatter___date], order: DESC}) {
        edges {
          node {
            frontmatter {
              title
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              date(formatString: "MMMM YYYY")
              published
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className="postslayoutcontainer">
        <h2>Reflections</h2>
        <br />
      <div className="post-list">
        {data.allMarkdownRemark.edges.map(edge => {
          return (
            <Link className="post" to={`/posts/${edge.node.fields.slug}`}>
              <div >
                {/* <Img
                  className="postimage"
                  fluid={
                    edge.node.frontmatter.featuredimage.childImageSharp.fluid
                  }
                /> */}
                <h3 className="post-title">{edge.node.frontmatter.title}</h3>
                  <p className="post-date">{edge.node.frontmatter.date}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
    </div>
  )
}
export default PostsLayout
