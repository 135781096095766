import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import prof from "../assets/prof.jpg"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../components/footer.jsx"
import Posts from "../components/postslayout.jsx"
import "../styles/index.scss"
import "typeface-noto-sans"
import "typeface-nunito"
import Head from "../components/head"

function Home() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div>
      <script async defer data-domain="sameerkapur.com" src="https://plausible.io/js/plausible.js"></script>
      <Head title="Home" />
      <div className="newcontainer">
        <div className="pic-container">
          <img src={prof} alt="profile" className="prof-pic" />
        </div>
        <div>
          <br />
          <h1>Hey, I'm Sameer</h1>
          <div className="social-media">
            <a
              href="https://twitter.com/sameerskapur"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social"
                src="https://image.flaticon.com/icons/png/512/23/23931.png"
                alt="Twitter"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/kapursameer/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social"
                src="https://image.flaticon.com/icons/png/512/61/61109.png"
                alt="LinkedIn"
              />
            </a>
            <a
              href="http://be.net/sameerskapur"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="social"
                src="https://image.flaticon.com/icons/png/512/87/87400.png"
                alt="Behance"
              />
            </a>
          </div>
          <p>
            I'm building software for banks and credit unions. If this sounds interesting to you, DM me on <a href="https://twitter.com/sameerskapur">Twitter</a>.
          </p>
          <div className="centered">
            <p className="currentlypara">currently:</p>
            <ul className="currentlyul">
              <li className="currently">
                ⚙️ Cofounder at Glide
              </li>
              <li className="currently">📚 Associate board member at CommonWealth Central CU</li>
              <li className="currently">
                🚀 Living in SF
              </li>
            </ul>
          </div>
          <br />
          <p>
            If you are looking to contact me, email me{" "}
            <a
              class="un"
              href="mailto:sameerskapur@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              here.
            </a>
          </p>
          {/* <div className="buttons">
            <button className="profileButton">my work</button>
            <button className="profileButton">about me</button>
            <button className="profileButton">contact</button>
            <button className="profileButton">resume</button> 
          </div> */}
        </div>
      </div>
      <hr className="home" />
      <Posts />
      <Footer />
    </div>
  )
}

export default Home
