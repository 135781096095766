import React from "react"

function Footer() {
  return (
    <div className="container">
      {/* <p>
        psst... i’ve made my site open source on Github & Glitch - feel free to
        copy it, share it, and remix it.
      </p> */}
    </div>
  )
}

export default Footer
